import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";
import React from "react";
import RightArrow from "../../assets/Icons/go.svg";
import Button from "../../components/Button";
import WhitePaperFooter from "../../components/WhitePaperFooter";
import "./MegaView.scss";

const Megaview = (props) => {
  return (
    <div>
      {props.MegaviewData &&
        props.MegaviewData.map((item, index) => (
          <React.Fragment key={index}>
            {" "}
            <Card className="list-area appsgridshadow" key={index}>
              <Link
                target="_blank"
                style={{ textDecoration: "none", color: "black" }}
                to={`/${item.slug_type}/${item.slug}`}
              >
                <Grid className="list" container direction="row">
                  {/* <Grid item className={item.MegaviewImageClassname ? item.MegaviewImageClassname :} sm={6}> */}
                  {/* megaview-card-content */}
                  <Grid
                    item
                    className={
                      item.height
                        ? "device-hide temp-height"
                        : "device-hide megaview-height"
                    }
                    sm={6}
                  >
                    <img
                      className="item-one"
                      src={item.mega_view_image}
                      alt={item.imageAltTxt}
                    />
                  </Grid>
                  <Grid
                    item
                    className={
                      item.height
                        ? " megaview-card-content temp-height"
                        : props.changeAccordianText
                        ? "megaview-card-content1 megaview-height"
                        : "megaview-card-content megaview-height"
                    }
                    sm={6}
                  >
                    <div className="title-and-content-area">
                      <div className="mega-view-title">{item.meta_title}</div>
                      {/* <div className="mega-view-content" dangerouslySetInnerHTML={{__html: `${item.MegaviewContent}`}} /> */}
                      <div className="mega-view-content">
                        {item.primary_description_1}
                      </div>
                    </div>

                    <Button
                      icon={
                        !props.variant &&
                        (props.displayFIndoutMore === "no" ? (
                          ""
                        ) : (
                          <img
                            className="effect-btn-svgicon"
                            src={RightArrow}
                            alt="click"
                          />
                        ))
                      }
                      customClassName={
                        props.changeAccordianText
                          ? "effect-btn-podcast"
                          : "effect-btn1"
                      }
                      color="primary"
                      label={
                        props.displayFIndoutMore === "no"
                          ? ""
                          : props.changeAccordianText
                          ? "Listen to the podcast"
                          : "Find out More"
                      }
                      tip={
                        props.changeAccordianText
                          ? "Listen to the podcast"
                          : "Find out More"
                      }
                      tooltip="yes"
                      view="show"
                    />
                    {/* </Grid> */}
                  </Grid>
                </Grid>
              </Link>
              <WhitePaperFooter
                WhitePaperLabel={item.type}
                download={item.download !== undefined ? item.download : ""}
                slug={item.slug}
                type={item.slug_type}
              />
            </Card>
            <br />
          </React.Fragment>
        ))}
    </div>
  );
};

export default Megaview;
